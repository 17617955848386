/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import moment from 'moment';

const groupDataByactivityType = (
  newData = [],
) => {
  const groupedData = newData.reduce((prev, next) => {
    const { activityTypeConfigId } = next;

    if (!prev[activityTypeConfigId]) {
      prev[activityTypeConfigId] = [];
    }

    prev[activityTypeConfigId].push(next);
    return prev;
  }, {});
  const uniqueKeys = Array.from(
    new Set([...Object.keys(groupedData)]),
  );
  const newSections = uniqueKeys.map((key) => {
    const grouped = groupedData[key];

    const groupedDatabyConfigID = grouped.reduce((prev, next) => {
      const { activityConfigId } = next;

      if (!prev[activityConfigId]) {
        prev[activityConfigId] = [];
      }

      prev[activityConfigId].push(next);
      return prev;
    }, {});

    const uniqueKeysConfig = Array.from(
      new Set([...Object.keys(groupedDatabyConfigID)]),
    );

    const configSection = uniqueKeysConfig.map((keynew) => ({
      activityConfigName: groupedDatabyConfigID[keynew][0].activityConfigName,
      history: groupedDatabyConfigID[keynew],
    }));

    return {
      title: groupedData[key][0].activityTypeConfigName,
      data: configSection,
    };
  });

  return newSections;
};

const groupDataByFloorName = (
  newData = [],
) => {
  const groupedData = newData.reduce((prev, next) => {
    const { propertyComponentFloorName } = next;

    if (!prev[propertyComponentFloorName]) {
      prev[propertyComponentFloorName] = [];
    }

    prev[propertyComponentFloorName].push(next);
    return prev;
  }, {});
  const uniqueKeys = Array.from(
    new Set([...Object.keys(groupedData)]),
  );
  const newSections = uniqueKeys.map((key) => ({
    floorName: groupedData[key][0].propertyComponentFloorName,
    weightage: groupedData[key].reduce((n, { weightage }) => n + JSON.parse(weightage), 0),
    completed: groupedData[key].reduce((n, { weightage, status }) => n + (status === 'Verified' ? JSON.parse(weightage) : 0), 0),
    data: groupedData[key],
    status: groupedData[key].reduce((n, { status }) => ((n === '' || n === status) ? status : 'In Progress'), ''),
  }));

  return newSections;
};

const reduceGroupActivityTypeAndNameStatus = (data) => {
  let newStatus = '';
  if (data.some(({ status }) => status === 'Verified')) {
    newStatus = 'In Progress';
  } else if (data.some(({ status }) => status === 'Submitted' || status === 'Uploading')) {
    newStatus = 'Submitted';
  }

  if (data.every(({ status }) => status === 'Verified')) {
    newStatus = 'Verified';
  }

  if (data.every(({ status }) => status === 'Submitted' || status === 'Uploading')) {
    newStatus = 'Submitted';
  }

  if (data.every(({ status }) => status === 'Yet to start')) {
    newStatus = 'Yet to start';
  }

  return newStatus;
};

const groupDataByactivityTypeAndName = (
  newData = [],
) => {
  const groupedData = newData.reduce((prev, next) => {
    const { activityConfigName } = next;

    if (!prev[activityConfigName]) {
      prev[activityConfigName] = [];
    }

    prev[activityConfigName].push(next);
    return prev;
  }, {});

  const uniqueKeys = Array.from(
    new Set([...Object.keys(groupedData)]),
  );
  const newSections = uniqueKeys.map((key) => ({
    activityConfigName: groupedData[key][0].activityConfigName,
    activityTypeConfigName: groupedData[key][0].activityTypeConfigName,
    weightage: groupedData[key].reduce((n, { weightage }) => n + JSON.parse(weightage), 0),
    noOfImages: groupedData[key].reduce((n, { noOfImages }) => n + JSON.parse(noOfImages), 0),
    noOfVideos: groupedData[key].reduce((n, { noOfVideos }) => n + JSON.parse(noOfVideos), 0),
    status: reduceGroupActivityTypeAndNameStatus(groupedData[key]),
    floors: groupDataByFloorName(groupedData[key]),
    data: groupedData[key],
    version: groupedData[key][0].version,
  }));

  return newSections;
};

const groupConstructionMediaByActivityName = (
  newData = [],
) => {
  const groupedData = newData.reduce((prev, next) => {
    const consPlanId = next?.consPlanId;

    if (!prev[consPlanId]) {
      prev[consPlanId] = [];
    }

    prev[consPlanId].push(next);
    return prev;
  }, {});

  const uniqueKeys = Array.from(
    new Set([...Object.keys(groupedData)]),
  );
  const newSections = uniqueKeys.map((key) => ({
    propertyComponentName: groupedData[key][0]
      ?.ProgressMediaPlan?.ConstructionPlan?.propertyComponentName,
    propertyComponentFloorName: groupedData[key][0]
      ?.ProgressMediaPlan?.ConstructionPlan?.propertyComponentFloorName,
    activityConfigName: groupedData[key][0]
      ?.ProgressMediaPlan?.ConstructionPlan?.activityConfigName,
    title: key,
    self: groupedData[key].filter((element) => element.submittedUserType === 'Customer'),
    cont: groupedData[key].filter((element) => element.submittedUserType === 'Contractor'),
    pm: groupedData[key].filter((element) => element.submittedUserType === 'Relationship Manager'),
    val: groupedData[key].filter((element) => element.submittedUserType === 'Valuer'),
  }));
  return newSections;
};

const groupActivityConfigSub = (
  newData = [],
) => {
  const groupedData = newData.reduce((prev, next) => {
    const { ActivityConfig } = next;
    const { activityTypeId } = ActivityConfig;

    if (!prev[activityTypeId]) {
      prev[activityTypeId] = [];
    }

    prev[activityTypeId].push(next);
    return prev;
  }, {});
  const uniqueKeys = Array.from(
    new Set([...Object.keys(groupedData)]),
  );

  const newSections = uniqueKeys.map((key) => {
    const grouped = groupedData[key];

    const groupedDatabyConfigID = grouped.reduce((prev, next) => {
      const { activityConfigId } = next;

      if (!prev[activityConfigId]) {
        prev[activityConfigId] = [];
      }

      prev[activityConfigId].push(next);
      return prev;
    }, {});

    const uniqueKeysConfig = Array.from(
      new Set([...Object.keys(groupedDatabyConfigID)]),
    );

    const configSection = uniqueKeysConfig.map((keynew) => {
      const groupDataConfigID = groupedDatabyConfigID[keynew];

      const groupedDataConfigReduced = groupDataConfigID.reduce((prev, next) => {
        const { ActivityConfig } = next;
        const { activitylevel } = ActivityConfig;

        if (!prev[activitylevel]) {
          prev[activitylevel] = [];
        }

        prev[activitylevel].push(next);
        return prev;
      }, {});

      const uniqueKeysConfiglevel = Array.from(
        new Set([...Object.keys(groupedDataConfigReduced)]),
      );

      const configSectionLevel = uniqueKeysConfiglevel.map((keylevel) => ({
        title: groupedDataConfigReduced[keylevel][0].ActivityConfig.activitylevel,
        data: groupedDataConfigReduced[keylevel],
      }));

      return {
        title: groupedDatabyConfigID[keynew][0].ActivityConfig.activityName,
        data: configSectionLevel,
      };
    });

    // const groupDataConfigID = groupedDatabyConfigID[keynew];

    return {
      title: groupedData[key][0].ActivityConfig.ActivityTypeConfig
        ? groupedData[key][0].ActivityConfig.ActivityTypeConfig.activityType
        : groupedData[key][0].ActivityTypeConfig.activityType,
      data: configSection,
    };
  });

  return newSections;
};

const getMonths = (start, end) => Array.from({ length: end.diff(start, 'month') + 1 }).map((_, index) => moment(start).add(index, 'month').format('MMM, YYYY'));

export const groupRequests = (
  data = [],
  isConstruction = false,
) => {
  const groupedTransactions = data.reduce((prev, next) => {
    const formattedDate = moment(isConstruction ? next.submittedAt : next.requestDate).format('MMM, YYYY');
    if (!prev[formattedDate]) {
      prev[formattedDate] = [];
    }

    prev[formattedDate].push(next);

    return prev;
  }, {});
  const uniqueKeys = Array.from(
    new Set([...Object.keys(groupedTransactions)]),
  );
  const keys = uniqueKeys.sort((a, b) => {
    const prev = moment(new Date(a)).unix();
    const next = moment(new Date(b)).unix();
    if (prev < next) return 1;
    if (prev > next) return -1;
    return 0;
  });
  const newSections = keys.map((key) => {
    const total = groupedTransactions[key].length;
    return {
      x: key,
      y: total,
    };
  });

  const monthArray = getMonths(
    moment().subtract(5, 'month').startOf('month'),
    moment().endOf('month'),
  );
  const month = [];
  for (let i = 0; i < 6; i++) {
    const items = newSections.find((item) => item.x === monthArray[i]);
    month.push({
      y: items ? items.y : 0,
      x: monthArray[i],
    });
  }
  return month;
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const resizedataURL = (datas, wantedWidth, wantedHeight) => new Promise((resolve) => {
  // We create an image to receive the Data URI
  const img = document.createElement('img');

  // When the event "onload" is triggered we can resize the image.
  img.onload = () => {
    // We create a canvas and get its context.
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // We set the dimensions at the wanted size.
    canvas.width = wantedWidth;
    canvas.height = wantedHeight;

    // We resize the image with the canvas method drawImage();
    ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

    const dataURI = canvas.toDataURL();
    console.log('dataURI', dataURI);
    // This is the return of the Promise
    resolve(dataURI);
  };

  // We put the Data URI in the image's src attribute
  img.src = datas;
});

const getOperatingSystem = (window) => {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
  if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

  return operatingSystem;
};

const getBrowser = (window) => {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    currentBrowser = 'Google Chrome';
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    currentBrowser = 'Mozilla Firefox';
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    currentBrowser = 'Internet Exployer';
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge';
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'YaBrowser';
  } else { console.log('Others'); }

  return currentBrowser;
};

export const OS = (window) => { getOperatingSystem(window); };
export const currentBrowser = (window) => { getBrowser(window); };

const getUID = () => {
  const nav = window.navigator;
  const { screen } = window;
  let guid = nav.mimeTypes.length;
  guid += nav.userAgent.replace(/\D+/g, '');
  guid += nav.plugins.length;
  guid += screen.height || '';
  guid += screen.width || '';
  guid += screen.pixelDepth || '';

  return guid;
};

const CONSENT_INDOSTAR = '\nI hereby request you to proceed this transaction vide generation of OTP through this Application and authorised by agreeing the terms and condition mentioned below (“Terms”). I hereby agree that my action of entering the OTP sent by NHFPL and clicking the “I Agree” button, constitute a valid acceptance by me of the terms contained herein and creates a binding and enforceable agreement between me and NHFPL.\n\nI further declare that all the particulars and information provided by me are true, correct, complete and up-to-date in all respects and that I have not withheld any information whatsoever.';
const TERMS_AND_CONDITION = ['I have completed 18 years of age.',
  'Details and data uploaded by me through this application are true and valid. In case of incorrect information, I am aware that NHFPL can reject further disbursement or recall my loan.',
  'I am aware that any tranche disbursement amount, are subjected to NHFPL internal review and evaluation for final authorisation. I am also aware that NHFPL can approve or reject my request based on the NHFPL internal policy',
  'My request for funds does not cast any responsibility or liability on NHFPL in any manner whatsoever.',
  'I am aware that, post availing this tranche payment my monthly interest amount will increase according to the amount of disbursal',
  'I assure that, this fund will be utilise only for the propose of further construction work in the collateral property'];

export {
  groupDataByactivityType,
  groupActivityConfigSub,
  groupDataByactivityTypeAndName,
  groupDataByFloorName,
  groupConstructionMediaByActivityName,
  getBase64FromUrl,
  resizedataURL,
  getOperatingSystem,
  getBrowser,
  getUID,
  CONSENT_INDOSTAR,
  TERMS_AND_CONDITION,
};
